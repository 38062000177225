.sidenav {
  &.ant-menu {
    padding: 24px 0;
  }
  .ant-menu-submenu {
    margin: 0 8px;
  }
  .ant-menu-item {
    margin: 0 22px;
    width: unset !important;
    padding: 0px 8px !important;
    border-radius: 8px;
    &::after {
      display: none;
    }
  }
  &.ant-menu:not(.ant-menu-horizontal)
    :is(
      .ant-menu-item-selected,
      .ant-menu-item:active,
      .ant-menu-item:hover,
      .ant-menu-submenu-title:active
    ) {
    color: #10be4f;
    &:not(.ant-menu-item:hover) {
      background-color: #4d7db81a;
    }
  }
}
