.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.text-input{
  padding: 16px 12px 4px 11px !important;
  height: 50px ;
}

.ant-select .ant-select-selector .ant-select-selection-overflow {
  padding: 16px 12px 4px 11px !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  padding: 16px 12px 4px 11px !important;
  top: 16px;
  display: flex;
  flex-direction: row !important;
}
.formLayout{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.formColLayout{
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100% !important;
  height: 50px  !important;
  padding: 10px 11px  !important;
}